var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
      enabled: true,
      trigger: 'enter',
      skipDiacritics: true,
      placeholder: 'Search...'
    },"pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: 20,
      position: 'top',
      perPageDropdown: [10, 20],
      dropdownAllowAll: true,
      setCurrentPage: 1,
      nextLabel: 'next',
      prevLabel: 'prev',
      rowsPerPageLabel: 'Rows per page',
      ofLabel: 'of',
      pageLabel: 'page', // for 'pages' mode
      allLabel: 'All'
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'link')?_c('span',[_c('a',{attrs:{"target":"_blank","href":props.row.link}},[_vm._v("Click Here")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('a',{attrs:{"href":_vm.generateCSVUrl,"download":_vm.filename,"target":"_blank"}},[_vm._v("Download this table")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }